<ng-container *ngIf="visitatie$ | async as visitatie">
  <div *ngIf="!visitatie">er zijn nog geen visitatie intakes voltooid.</div>
  <form (ngSubmit)="versturen()" [formGroup]="form">

    <div class="row">
      <div class="twelve columns">
        <h1>Visitatie gegevens</h1>
      </div>
    </div>

    <div class="row">
      <div class="twelve columns">
        <p class="label">
          <span class="label__key">Instelling:</span>
          <span class="label__value">{{visitatie.ziekenhuisNaam}}</span>
        </p>
        <p class="label">
          <span class="label__key">visitatiedatum: </span>
          <span class="label__value">{{ visitatie.visitatieDatum | date:'dd-MM-yyyy'}}</span>
        </p>
        <p class="label">
          <span class="label__key">vorige visitatiedatum:</span>
          <span class="label__value">{{ visitatie.vorigeVisitatieDatum | date:'dd-MM-yyyy'}}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="twelve columns">
        <div class="list">
          <span class="list__title">NHV visitatiecommissie</span>
          <ul class="list__items">
            <li class="list__item" *ngFor="let persoon of visitatie.visitatiecommissie">{{persoon.naam}}</li>
          </ul>
        </div>
        <div class="list">
          <span class="list__title">NVVC kwaliteitcommissie</span>
          <ul class="list__items">
            <li class="list__item" *ngFor="let persoon of visitatie.kwaliteitcommissie">{{persoon}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="twelve columns">
        <a (click)="downloadIntake(visitatie.id)" class="red-link">
          <mat-icon class="red-icon" aria-label="download intake">cloud_download</mat-icon> intake verslag</a>
        <a (click)="downloadVisitatie(visitatie.id)" class="red-link">
          <mat-icon class="red-icon" aria-label="download visitatie">cloud_download</mat-icon> (voorlopig) visitatieverslag</a>
        <a (click)="downloadSBHFL(visitatie.id)" class="red-link">
          <mat-icon class="red-icon" aria-label="download sbhfl">cloud_download</mat-icon> SBHFL verklaring</a>
      </div>
    </div>
    <div class="row" *ngIf="form.dirty">
      <div class="twelve columns">
        <div class="u-spacer-small"></div>
        <mat-card class="warning">
            Sla je gegevens op voordat je een voorlopig visitatieverslag download, anders mis je mogelijk nieuw ingevulde gegevens in dit verslag.
          </mat-card>
      </div>
    </div>
  <div class="row">
    <div class="twelve columns">
      <h2>Verslagen</h2>
    </div>
  </div>

  <mat-vertical-stepper *ngIf="visitatie">
    <mat-step *ngFor="let onderwerp of visitatie.visiteurVragen" [label]="onderwerp.titel">
        <ng-container *ngFor="let groep of onderwerp.groepen">
          <ng-container *ngFor="let vraag of groep.vragen">
            <div style="margin-bottom: 1em;">
              <open-vraag   *ngIf="vraag | instanceof: 'openvraag'" [vraag]="vraag" [form]="form.get(onderwerp.key).get(groep.key)"></open-vraag>
              <keuze-vraag  *ngIf="vraag | instanceof: 'keuzevraag'" [vraag]="vraag" [form]="form.get(onderwerp.key).get(groep.key)"></keuze-vraag>
            </div>
          </ng-container>
        </ng-container>
    </mat-step>
  </mat-vertical-stepper>

  <div class="row">
    <div class="twelve columns">
        <button type="submit" [disabled]="disableButton" mat-flat-button color="primary">opslaan</button>
    </div>
  </div>
  </form>

</ng-container>
