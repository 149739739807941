import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {

  public links$: Observable<string>;

  constructor(private service: ContentService) { }

  ngOnInit(): void {
    this.links$ = this.service.getLinks();
  }

}
