import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { OnderwerpState } from './onderwerp.state';
import { Onderwerp } from '../models/onderwerp';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'Onderwerp', idKey: 'key' })
export class OnderwerpStore extends EntityStore<OnderwerpState, Onderwerp> {
  constructor() {
    super();
  }
}
