import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DATE_LOCALE,
  DateAdapter,
  MAT_DATE_FORMATS
} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';

import { environment } from '../environments/environment';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FormulierZiekenhuisComponent } from './components/formulier-ziekenhuis/formulier-ziekenhuis.component';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { AuthorizedGuard } from './guards/authorized.guard';
import { GebruikersRol } from './models/gebruikersrol';
import { VragenHoofdComponent } from './components/vragen-hoofd/vragen-hoofd.component';
import { VragenMedewerkerComponent } from './components/vragen-medewerker/vragenmedewerker.component';
import { VragenvisiteurComponent } from './components/vragen-visiteur/vragen-visiteur.component';
import { ListVisitatieComponent } from './components/list-visitatie/list-visitatie.component';
import { AddVisitatieComponent } from './components/add-visitatie/add-visitatie-component';
import { ListGebruikersComponent } from './components/list-gebruikers/list-gebruikers.component';
import { AddGebruikersComponent } from './components/add-gebruikers/add-gebruikers.component';
import { EditGebruikersComponent } from './components/edit-gebruikers/edit-gebruikers.component';
import { PasswordlessComponent } from './components/passwordless/passwordless.component';
import { BASE_API_URL, BASE_WEBSITE_URL } from './constants';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CookiesService } from './services/cookie.service';
import { AuthenticationService } from './services/Authentication.service';
import { GebruikersService } from './services/gebruikers.service';
import { IdentityQuery } from './data/identity.query';
import { IdentityStore } from './data/identity.store';
import { AuthenticationInterceptor } from './interceptors/Authentication.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { CacheInterceptor } from './interceptors/cache.interceptor';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderQuery } from './data/loader.query';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { OpenVraagComponent } from './components/openvraag/open-vraag.component';
import { KeuzeVraagComponent } from './components/keuzevraag/keuze-vraag.component';
import { VraagMapper } from './mappers/vraag.mapper';
import { QuestionDatumComponent } from './components/question-datum/question-datum.component';
import { DocumentenService } from './services/documenten.service';
import { VisitatieService } from './services/visitatie.service';
import { InstanceofPipe } from './pipes/instanceof.pipe';
import { MedewerkerToewijzingComponent } from './components/medewerker-toewijzing/medewerker-toewijzing.component';
import { VragenmedewerkerService } from './services/vragenmedewerker.service';
import { NieuwsComponent } from './components/nieuws/nieuws.component';
import { LinksComponent } from './components/links/links.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { MarkdownModule } from 'ngx-markdown';
import { ContentService } from './services/content.service';
import { LoginComponent } from './components/login/login.component';
import { TarievenComponent } from './components/tarieven/tarieven.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoggingSink } from './logging/loggingsink.handler';

const appRoutes: Routes = [
  {
    path: 'intake',
    component: FormulierZiekenhuisComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Hoofd }
  },
  {
    path: 'intake/vragen/:id',
    component: VragenHoofdComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Hoofd }
  },

  {
    path: 'intake/medewerker',
    component: VragenMedewerkerComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Medewerker }
  },

  {
    path: 'visiteur',
    component: VragenvisiteurComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Visiteur }
  },

  {
    path: 'visitaties',
    component: ListVisitatieComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Beheerder }
  },
  {
    path: 'visitaties/toevoegen',
    component: AddVisitatieComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Beheerder }
  },

  {
    path: 'gebruikers',
    component: ListGebruikersComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Beheerder }
  },
  {
    path: 'gebruikers/toevoegen',
    component: AddGebruikersComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Beheerder }
  },
  {
    path: 'gebruikers/:id/wijzigen',
    component: EditGebruikersComponent,
    canActivate: [AuthenticatedGuard, AuthorizedGuard],
    data: { rol: GebruikersRol.Beheerder }
  },

  { path: 'otp/:email', component: PasswordlessComponent },
  {
    path: 'authenticated',
    component: UnauthorizedComponent,
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'nieuws',
    component: NieuwsComponent,
  },
  {
    path: 'links',
    component: LinksComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'tarieven',
    component: TarievenComponent
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: '', redirectTo: 'authenticated', pathMatch: 'full' }
];

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    PasswordlessComponent,
    LoaderComponent,
    OpenVraagComponent,
    KeuzeVraagComponent,
    AddGebruikersComponent,
    EditGebruikersComponent,
    ListGebruikersComponent,
    QuestionDatumComponent,
    ListVisitatieComponent,
    AddVisitatieComponent,
    InstanceofPipe,
    FormulierZiekenhuisComponent,
    VragenHoofdComponent,
    MedewerkerToewijzingComponent,
    VragenMedewerkerComponent,
    VragenvisiteurComponent,
    NieuwsComponent,
    LinksComponent,
    HomeComponent,
    ContactComponent,
    LoginComponent,
    TarievenComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    MatStepperModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatTabsModule,
    MatInputModule,
    FontAwesomeModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- enable to trace routes
    ),
    DeviceDetectorModule.forRoot(),
    MarkdownModule.forRoot(),
    FontAwesomeModule,
  ],
  providers: [
    CookiesService,
    AuthenticationService,
    AuthorizedGuard,
    AuthenticatedGuard,
    GebruikersService,
    IdentityQuery,
    IdentityStore,
    LoaderQuery,
    LoaderService,
    VraagMapper,
    GebruikersService,
    DocumentenService,
    GebruikersService,
    VisitatieService,
    ContentService,
    VragenmedewerkerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },

    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: BASE_API_URL, useValue: environment.baseApiUrl },
    { provide: BASE_WEBSITE_URL, useValue: environment.visitatieWebsiteUrl },
    {provide: ErrorHandler, useClass: LoggingSink }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
