<H1>Openstaande visitaties</H1>
<mat-list>
  <mat-list-item *ngFor="let visitatie of (visitaties$ | async)">
    <p mat-line>
      <span class="visitatie-item__header__naam">{{visitatie.ziekenhuisNaam}}</span>
      <span class="visitatie-item__header">{{ visitatie.visitatieDatum | date:'dd-MM-yyyy'}}</span>
      <span class="visitatie-item__header">status:
        <select #select (change)="updateStatus(visitatie.id, select.value)">
          <option *ngFor="let status of [VisitatieStatus.intake,VisitatieStatus.bespreken, VisitatieStatus.definitief]"
                  [value]="status"
                  [selected]="status === visitatie.status">
            {{VisitatieStatus[status]}}
          </option>
        </select>
      </span>
    </p>
    <p mat-line>
      <mat-progress-bar mode="determinate" value="{{ (visitatie.status / 30) * 100 }}"></mat-progress-bar>
    </p>
    <p mat-line>
      <span class="visitatie-item__action" *ngIf="visitatie.status !== VisitatieStatus.intake">
        <button mat-button (click)="downloadVerklaring(visitatie.id)" color="primary">
          <mat-icon aria-label="download intake">cloud_download</mat-icon> sbhfl verklaring
        </button>
      </span>
      <span class="visitatie-item__action" *ngIf="visitatie.status !== VisitatieStatus.intake">
        <button mat-button (click)="downloadVisitatie(visitatie.id)" color="primary">
          <mat-icon aria-label="download intake">cloud_download</mat-icon> visitatieverslag
        </button>
      </span>
      <span class="visitatie-item__action">
        <button mat-button (click)="downloadIntake(visitatie.id)" color="primary">
          <mat-icon aria-label="download intake">cloud_download</mat-icon> intake verslag
        </button>
      </span>
    </p>
  </mat-list-item>
</mat-list>
<button mat-raised-button color="primary" [routerLink]="['/visitaties/toevoegen']">
  Visitatie toevoegen
</button>
