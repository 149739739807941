<ng-container *ngIf="supportedBrowser; else notsupported">
  <div class="container">
    <div class="row">
      <nav class="twelve columns">
        <mat-toolbar>
          <a mat-button color="primary" routerLink="/">
            <fa-icon [icon]="faHome"></fa-icon> home
          </a>
          <a mat-button color="primary" routerLink="/nieuws">
            nieuws
          </a>
          <a mat-button color="primary" routerLink="/tarieven">
            tarieven
          </a>
          <ng-container *ngIf="(authenticated$ | async) else login">

            <a mat-button color="primary" routerLink="/intake"
              *ngIf="(rol$ | async) === GebruikersRol.Hoofd || (rol$ | async) === GebruikersRol.Medewerker">
              intake
            </a>
            <a mat-button color="primary" routerLink="/links"
            *ngIf="(rol$ | async) === GebruikersRol.Beheerder || (rol$ | async) === GebruikersRol.Visiteur">
              links
            </a>
            <a mat-button color="primary" routerLink="/contact"
              *ngIf="(rol$ | async) === GebruikersRol.Beheerder || (rol$ | async) === GebruikersRol.Visiteur">
              contact
            </a>
            <a mat-button color="primary" routerLink="/visitaties" *ngIf="(rol$ | async) === GebruikersRol.Beheerder">
              visitaties
            </a>
            <a mat-button color="primary" routerLink="/gebruikers" *ngIf="(rol$ | async) === GebruikersRol.Beheerder">
              gebruikers
            </a>
            <a mat-button color="primary" (click)="loguit()" class="user-menu">
              <fa-icon [icon]="faSignOutAlt"></fa-icon> loguit
            </a>
          </ng-container>
          <ng-template #login>
            <a mat-button color="primary" routerLink="/login" class="user-menu">
              <fa-icon [icon]="faSignInAlt"></fa-icon> login
            </a>
          </ng-template>
        </mat-toolbar>
      </nav>
    </div>
    <div class="row">
      <div class="u-spacer-small"></div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <nhv-loader></nhv-loader>
</ng-container>
<ng-template #notsupported>
  <mat-card class="warning">
    <h1>Deze browser wordt niet ondersteund</h1>
    <p>
      Wij kunnen de werking van onze website niet garanderen op deze browser.
      Gebruik Daarom aub een van de volgende browsers:
    </p>
    <ul>
      <li *ngFor="let browser of supportedBrowsers">{{ browser }}</li>
    </ul>
    <p>
      Mocht u op uw werkplek geen van bovenstaande browsers beschikbaar zijn dan
      kan u ook uw eigen computer, laptop of tablet gebruiken.
    </p>
    <p>U kan onderstaande url in een van bovenstaande browsers gebruiken om verder te gaan:</p>
    <code>{{ currentUrl }}</code>
    <p>Excuses voor het ongemak.</p>
  </mat-card>
</ng-template>
