<div *ngIf="geenRol">
  <H1>Welkom op de NHV visitatie applicatie</H1>
  <p>Op dit moment is er nog geen rol aan u toegekend.
    Indien u nieuw bent of u denkt dat er is iets mis, neem dan contact op met de beheerder(s).</p>

  <ng-container *ngIf="(beheerders$ | async)">
  <h2> beheerders:</h2>
  <ul>
    <li *ngFor="let b of (beheerders$ | async)">{{ b.naam }} ({{b.email}})</li>
  </ul>
  </ng-container>
</div>
