<div class="row">
  <div class="twelve columns">
    <h2>{{ titel }}</h2>
    <p>{{ beschrijving }}</p>
  </div>
</div>

<form (ngSubmit)="versturen()" [formGroup]="form">

  <ng-container *ngIf="!herhalend">
    <ng-container *ngFor="let groep of groepen">
      <div class="row">
        <div class="twelve columns">
          <h2>{{ groep.titel }}</h2>
        </div>
      </div>
      <div *ngFor="let vraag of groep.vragen" class="row">
        <div class="twelve columns">
          <open-vraag   *ngIf="vraag | instanceof: 'openvraag'" [vraag]="vraag" [form]="form.get(groep.key)"></open-vraag>
          <keuze-vraag  *ngIf="vraag | instanceof: 'keuzevraag'" [vraag]="vraag" [form]="form.get(groep.key)"></keuze-vraag>
        </div>
      </div>
    </ng-container>
  </ng-container>

    <ng-container *ngIf="herhalend">
      <div class="row">
        <div class="twelve columns">
          <mat-accordion class="example-headers-align">
            <mat-expansion-panel *ngFor="let groep of groepen" [expanded]="activeTab === groep.key" (opened)="activeTab = groep.key" hideToggle>
              <mat-expansion-panel-header *ngIf="activeTab !== groep.key">
                <mat-panel-title>
                  <mat-icon>edit</mat-icon><span style="margin-left: 20px">{{ getTitle(groep) }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="u-spacer-small"></div>
              <div *ngFor="let vraag of groep.vragen" class="u-full-width">
                  <open-vraag   *ngIf="vraag | instanceof: 'openvraag'" [vraag]="vraag" [form]="form.get(groep.key)"></open-vraag>
                  <keuze-vraag  *ngIf="vraag | instanceof: 'keuzevraag'" [vraag]="vraag" [form]="form.get(groep.key)"></keuze-vraag>
              </div>
              <mat-action-row *ngIf="!laatsteVraag">
                <button mat-button color="primary" (click)="verwijder($event, groep)"><mat-icon>delete</mat-icon>verwijderen</button>
              </mat-action-row>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </ng-container>

  <div class="row">
    <div class="twelve columns">
        <div class="u-spacer-small"></div>
    </div>
  </div>
<ng-container *ngIf="showNotification">
  <div class="row">
    <div class="twelve columns">
        <mat-card class="warning">Er zijn verplichte velden in dit formulier, deze moeten eerst ingevuld worden voordat het formulier opgeslagen kan worden.</mat-card>
    </div>
  </div>

  <div class="row">
    <div class="twelve columns">
        <div class="u-spacer-small"></div>
    </div>
  </div>
</ng-container>

  <div class="row">
      <div class="twelve columns">
        <button routerLink="/intake" mat-flat-button color="secondary" style="margin-right: 20px">terug</button>
        <button *ngIf="herhalend" (click)="kopie($event)" mat-flat-button color="secondary" style="margin-right: 20px">toevoegen</button>
        <button type="submit" [disabled]="disableButton" mat-flat-button color="primary">opslaan</button>
      </div>
  </div>
</form>
