import { Component, OnInit } from '@angular/core';
import { VisitatieService } from '../../services/visitatie.service';
import { Observable } from 'rxjs';
import { Visitatie } from '../../models/visitatie';
import { VisitatieStatus } from '../../models/visitatie-status';
import { DocumentenService } from '../../services/documenten.service';
import {GebruikersRol} from '../../models/gebruikersrol';
import {take} from 'rxjs/operators';

@Component({
  selector: 'nhv-list-visitatie',
  templateUrl: './list-visitatie.component.html',
  styleUrls: ['./list-visitatie.component.css']
})
export class ListVisitatieComponent implements OnInit {

  // nodig om de string waarde van een enum te laten zien
  VisitatieStatus = VisitatieStatus;

  public visitaties$: Observable<Visitatie[]>;

  public Ziekenhuizen = ['Rijnstate', 'Slingerland', 'UMC utrecht'];
  public SelectedZiekenhuis: string;
  public VisitatieDatum: Date;
  public foutmelding: string;
  public file: any;

  constructor(private service: VisitatieService, private docservice: DocumentenService) { }

  ngOnInit() {
    this.visitaties$ = this.service.GetAllVisitaties();
  }

  downloadIntake(visitatieId: string): any {
    this.docservice.downloadIntake(visitatieId)
      .subscribe(file => window.open(window.URL.createObjectURL(file)));
  }

  downloadVisitatie(visitatieId: string): any {
    this.docservice.downloadVisitatie(visitatieId)
      .subscribe(file => window.open(window.URL.createObjectURL(file)));

  }

  downloadVerklaring(visitatieId: string): any {
    this.docservice.downloadVerklaring(visitatieId)
      .subscribe(file => window.open(window.URL.createObjectURL(file)));

  }

  updateStatus(visitatieId: string, status: string): any {
    if (!isNaN(Number(status))) {
    }
    const code = Number(status);

    let newStatus = VisitatieStatus.intake;
    if (code === 20) {
       newStatus = VisitatieStatus.bespreken;
    }
    if (code === 30) {
       newStatus = VisitatieStatus.definitief;
    }

    return this.service.setVisitatieStatus(visitatieId, newStatus).pipe(take(1)).subscribe(() => {
      this.visitaties$ = this.service.GetAllVisitaties();
    });
  }

  protected readonly GebruikersRol = GebruikersRol;
}
