<form [formGroup]="gebruikerform">
    <p class="u-full-width">
        <mat-form-field class="u-full-width">
            <input matInput formControlName="naam" placeholder="naam">
        </mat-form-field>
    </p>
    <p class="u-full-width">
        <mat-form-field class="u-full-width">
            <input matInput formControlName="email" placeholder="email">
        </mat-form-field>
    </p>
    <p class="u-full-width">
        <mat-select placeholder="gebruikersrol" formControlName="rol">
            <mat-option *ngFor="let rol of rollen" [value]="rol">
                {{GebruikersRol[rol]}}
            </mat-option>
        </mat-select>
    </p>
    <p *ngIf="gebruikerform.value.rol === '3'" class="u-full-width">
        <mat-form-field class="u-full-width">
            <input matInput formControlName="ziekenhuis" placeholder="ziekenhuis">
        </mat-form-field>
    </p>
</form>
<div class="u-spacer-small"></div>
{{ message }}
<div class="u-spacer-small"></div>
<button mat-raised-button color="secondary" [routerLink]="['/gebruikers']">annuleren</button>
<button mat-raised-button color="primary" (click)="toevoegen()" [disabled]="!enabled">toevoegen</button>
