import { Component, OnInit } from '@angular/core';
import { VisitatieService } from '../../services/visitatie.service';
import { DocumentenService } from '../../services/documenten.service';
import {VraagMapper} from '../../mappers/vraag.mapper';
import { first, map, tap } from 'rxjs/operators';
import { Visitatie } from '../../models/visitatie';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import produce from 'immer';
import { Onderwerp } from '../../models/onderwerp';
import { KeuzeVraag } from '../../models/keuzevraag';
import { OpenVraag } from '../../models/openvraag';

@Component({
  selector: 'nhv-vragen-visiteur',
  templateUrl: './vragen-visiteur.component.html',
  styleUrls: ['./vragen-visiteur.component.scss']
})
export class VragenvisiteurComponent implements OnInit {

  public visitaties$: Observable<Visitatie[]>;
  public visitatie$: Observable<Visitatie>;

  public form: FormGroup;
  public onderwerpen: Onderwerp[];
  public disableButton = false;

  constructor(
    private service: VisitatieService,
    private docservice: DocumentenService,
    private mapper: VraagMapper) { }

  ngOnInit(): void {
    this.visitaties$ = this.service.GetTeBesprekenVisitaties();

    this.visitatie$ = this.visitaties$.pipe(
      first(),
      map(n => n[0]),
      tap(v => {
        this.form = this.mapper.FromOnderwerpen(v.visiteurVragen);
      })
    );
  }

  optionChanged(): void {
  }

  versturen(): void {
    this.visitaties$.pipe(
      first(),
      map(n => n[0]),
      tap(v => {
        const onderwerpen = produce(this.makeCopy(v.visiteurVragen), draft => {
          v.visiteurVragen.forEach((onderwerp, oi) => {
            onderwerp.groepen.forEach((groep, gi) => {
              groep.vragen.forEach((vraag, vi) => {
                draft[oi].groepen[gi].vragen[vi].antwoord = this.form.value[onderwerp.key][groep.key][vraag.key];
              });
            });
          });
        });
        this.service.UpdateVisitatie(onderwerpen, v.ziekenhuisNaam);
        this.form.markAsPristine();
      }),
    ).subscribe();
  }

  private makeCopy(onderwerpen: Array<Onderwerp>): Array<Onderwerp> {

    return produce(onderwerpen, draft => {
      draft.forEach(draftonderwerp => {
        draftonderwerp.groepen.forEach(draftgroep => {
          draftgroep.vragen = draftgroep.vragen.map(draftvraag => {
            if ((draftvraag as KeuzeVraag).opties !== undefined || null) {
              return new KeuzeVraag(draftvraag);
            } else {
              return new OpenVraag(draftvraag);
            }
          });
        });
      });
    });
  }

  downloadIntake(visitatieId: string): any {
    this.docservice.downloadIntake(visitatieId)
      .subscribe(file => window.open(window.URL.createObjectURL(file)));
  }

  downloadVisitatie(visitatieId: string): any {
    this.docservice.downloadVisitatie(visitatieId)
      .subscribe(file => window.open(window.URL.createObjectURL(file)));
  }

  downloadSBHFL(visitatieId: string): any {
    this.docservice.downloadVerklaring(visitatieId)
      .subscribe(file => window.open(window.URL.createObjectURL(file)));
  }
}
