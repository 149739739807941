import { Pipe, PipeTransform } from '@angular/core';
import { OpenVraag } from '../models/openvraag';
import { KeuzeVraag } from '../models/keuzevraag';

@Pipe({
  name: 'instanceof'
})
export class InstanceofPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (args.toLowerCase()) {
      case 'openvraag':
        return value instanceof OpenVraag;
      case 'keuzevraag':
        return value instanceof KeuzeVraag;
      default:
        return false;
    }
  }
}
