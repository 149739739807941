import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoaderStore } from '../data/loader.store';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  constructor(
    private router: Router,
    private loaderStore: LoaderStore) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd)
      ).subscribe(_ => {
        this.Reset();
      });
  }

  Reset(): any {
    this.loaderStore.update(state => state = {
      ...state,
      loading: false,
      workBeingDone: 0
    });
  }

  Doing(): any {
    this.loaderStore.update(state => state = {
      ...state,
      loading: true,
      workBeingDone: state.workBeingDone + 1
    });
  }

  Done(): void {
    this.loaderStore.update(state => state = {
      ...state,
      loading: state.workBeingDone > 1,
      workBeingDone: state.workBeingDone === 0 ? 0 : state.workBeingDone - 1
    });
  }
}
