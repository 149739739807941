import { Component, OnInit } from '@angular/core';
import { tap, flatMap, map, concatMap, debounceTime, single, first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { VisitatieService } from '../../services/visitatie.service';
import produce from 'immer';
import { VragenGroep } from '../../models/vragengroep';
import { VisitatieQuery } from '../../data/visitatie.query';
import { VraagMapper } from '../../mappers/vraag.mapper';
import { Onderwerp } from '../../models/onderwerp';
import { OpenVraag } from '../../models/openvraag';
import { KeuzeVraag } from '../../models/keuzevraag';

@Component({
  selector: 'vragen-hoofd',
  templateUrl: './vragen-hoofd.component.html',
  styleUrls: ['./vragen-hoofd.component.css']
})
export class VragenHoofdComponent implements OnInit {

  public titel: string;
  public beschrijving: string;

  public herhalend: boolean;
  public verplicht: boolean;

  public typing = false;
  public form: FormGroup;
  public formSub: Subscription;
  public groepen: VragenGroep[];
  public activeTab: string;

  private templateGroup: VragenGroep;


  public get laatsteVraag(): boolean {
    return this.groepen.length <= 1;
  }


  constructor(
    private query: VisitatieQuery,
    private route: ActivatedRoute,
    private mapper: VraagMapper,
    private service: VisitatieService,
    private router: Router) { }

  ngOnInit() {
    this.loadQuestions();
  }

  private loadQuestions() {

    if (this.formSub) {
      this.formSub.unsubscribe();
    }

    this.route.params.pipe(
      map(p => p.id),
      flatMap(id => {
        return this.query.hoofdVragen$.pipe(
          map(onderwerpen => onderwerpen.filter(onderwerp => onderwerp.key === id)[0]),
          tap((onderwerp: Onderwerp) => {
            this.titel = onderwerp.titel;
            this.beschrijving = onderwerp.beschrijving;
            this.herhalend = onderwerp.herhalend;
            this.verplicht = onderwerp.verplicht || false;
          }),

          concatMap((onderwerp) => {
            if (this.herhalend) {
              this.setTemplate(onderwerp.groepen[0]);
            }
            this.form = this.mapper.FromGroups(produce(onderwerp.groepen, () => { }));
            this.groepen = produce(onderwerp.groepen, () => { });
            this.activeTab = '' + Math.max.apply(Math, this.groepen.map(g => +g.key));

            return this.form.valueChanges.pipe(
              tap(() => this.typing = true),
              debounceTime(1000),
              tap(newFormValues => {

                // update groepen antwoorden
                // create updated onderwerp
                const nieuwOnderwerp = produce(onderwerp, draft => {
                  draft.groepen = this.groepen.map(g => this.CopyVragenGroep(g));

                  draft.groepen = draft.groepen.map((g, gi) => {

                    return produce(g, groep => {
                      if (groep.ingevuld) {
                        groep.ingevuld = this.form.get(groep.key).valid;
                      } else {
                        groep.ingevuld = this.form.get(groep.key).valid && (this.form.touched || this.form.dirty);
                      }

                      groep.vragen.forEach((vraag, vi) => {
                        draft.groepen[gi].vragen[vi].antwoord = newFormValues[groep.key][vraag.key];
                      });
                    });

                  });

                  if (onderwerp.ingevuld) {
                    draft.ingevuld = this.form.valid;
                  } else {
                    draft.ingevuld = this.form.valid && (this.form.touched || this.form.dirty);
                  }

                });

                // invoke update on service here
                this.service.UpdateOnderwerpen(nieuwOnderwerp);
              }),
              tap(() => this.typing = false)
            );
          }
          ));
      })
    ).subscribe();
  }

  private setTemplate(groep: VragenGroep) {

    this.templateGroup = produce(this.CopyVragenGroep(groep), draft => {
      draft.ingevuld = false;
      draft.vragen.forEach(v => v.antwoord = '');
    });
  }

  private CopyVragenGroep(groep: VragenGroep): VragenGroep {
    return produce(groep, draftgroep => {
      // for some reason immer cant handle the shit going on below and keeps the same reference for questions.
      draftgroep.vragen = draftgroep.vragen.map(v => {
        if (v instanceof OpenVraag) {
          return new OpenVraag(v);
        } else if (v instanceof KeuzeVraag) {
          return new KeuzeVraag(v);
        }
      });
    });
  }

  public kopie(event: Event) {


    let newGroup = this.CopyVragenGroep(this.templateGroup);
    newGroup = produce(newGroup, draftgroup => {
      draftgroup.key = '' + (Math.max.apply(Math, this.groepen.map(g => (+g.key))) + 1);
    });

    this.groepen = produce(this.groepen, draftGroepen => { draftGroepen.push(newGroup); });

    this.form.addControl(newGroup.key, this.mapper.FromVragen(newGroup.vragen));
    this.activeTab = newGroup.key;

    event.stopPropagation();
    return false;
  }

  public verwijder(event: Event, groep: VragenGroep) {

    this.groepen = this.groepen.filter(g => g.key !== groep.key);
    this.form.removeControl(groep.key);
    event.stopPropagation();
    return false;
  }

  public versturen() {
    this.route.params.pipe(
      map(p => p.id),
      flatMap(id => {
        return this.query.hoofdVragen$.pipe(
          first(),
          map(onderwerpen => onderwerpen.filter(onderwerp => onderwerp.key === id)[0]),
          tap(onderwerp => {
            const nieuwOnderwerp = produce(onderwerp, draft => {
              draft.groepen = this.groepen.map(g => this.CopyVragenGroep(g));

              draft.groepen = draft.groepen.map((g, gi) => {
                return produce(g, groep => {
                    groep.ingevuld = this.form.get(groep.key).valid;

                    groep.vragen.forEach((vraag, vi) => {
                      draft.groepen[gi].vragen[vi].antwoord = this.form.value[groep.key][vraag.key];
                    });
                });
              });

              if (this.herhalend) {
                draft.ingevuld = (this.verplicht && this.form.valid) || !this.verplicht;
              } else {
                draft.ingevuld = this.form.valid;
              }
            });

            // invoke update on service here
            this.service.UpdateOnderwerpen(nieuwOnderwerp);
          }),
        );
      })
    ).subscribe(() => {
      this.router.navigate(['/intake']);
    });
  }

  public getTitle(groep: VragenGroep): string {
    const groepform = this.form.get(groep.key) as FormArray;
    const formwaarde = groepform.get(groep.titelkey);
    const vraag = groep.vragen.find(v => v.key === groep.titelkey);

    if ((vraag as KeuzeVraag).opties) {
      return formwaarde && (vraag as KeuzeVraag).opties[formwaarde.value] || groep.titel;
    }

    return formwaarde && formwaarde.value || groep.titel;
  }

  public get showNotification(): boolean {
    if (this.herhalend) {
      return (this.verplicht && (this.form.invalid));
    } else {
      return this.form.invalid && (this.form.touched || this.form.dirty);
    }
  }

  public get disableButton(): boolean {
    if (this.herhalend) {
      return (this.verplicht && this.form.invalid) || this.typing;
    } else {
      return this.form.invalid || this.typing;
    }
  }
}
