import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { flatMap, tap, map, first } from 'rxjs/operators';
import { Visitatie } from '../models/visitatie';
import { VisitatieStatus } from '../models/visitatie-status';
import { produce } from 'immer';
import { Gebruiker } from '../models/gebruiker';
import { BASE_API_URL } from '../constants';
import { VisitatieStore } from '../data/visitatie.store';
import { IdentityQuery } from '../data/identity.query';
import { KeuzeVraag } from '../models/keuzevraag';
import { OpenVraag } from '../models/openvraag';
import { MedewerkerFunctie } from '../models/MedewerkerFunctie';
import { Onderwerp } from '../models/onderwerp';

@Injectable()
export class VisitatieService {

  private initiated = false;

  constructor(
    private store: VisitatieStore,
    private client: HttpClient,
    private identityQuery: IdentityQuery,
    @Inject(BASE_API_URL) private baseApiUrl
  ) { }

  public Initiate(force = false): Observable<boolean> {
    if (!force && this.initiated) {
      return of(this.initiated);
    }

    return this.identityQuery.ziekenhuis$.pipe(flatMap(ziekenhuis => {
      const httpOptions = {
        params: new HttpParams()
          .append('ziekenhuisNaam', ziekenhuis)
      };

      return this.client.get<Visitatie>(`${this.baseApiUrl}/api/visitatie/GetNextVisitatie`, httpOptions)
        .pipe(
          map(v => {
            if (!v) {
              return null;
            }

            return produce(v, visitatie => {
              visitatie.hoofdVragen.forEach(onderwerp => {
                onderwerp.groepen.forEach(groep => {
                  groep.vragen = groep.vragen.map(vraag => {
                    if ((vraag as KeuzeVraag).opties !== undefined || null) {
                      return new KeuzeVraag(vraag);
                    } else {
                      return new OpenVraag(vraag);
                    }
                  });
                });
              });
            });
          }),
          tap(visitatie => {
            if (visitatie) {
              this.store.update({ ...visitatie });
            } else {
              this.store.reset();
            }
          }),
          map(v => v != null || undefined),
          tap(succes => this.initiated = succes)
        );
    }));
  }

  public AddAangewezenMedewerkers(visitatieId: string, email: string, functie: MedewerkerFunctie): Observable<any> {
    const request = {
      EmailMedewerker: email,
      functie: functie,
      visitatieId: visitatieId
    };

    return this.client.put(`${this.baseApiUrl}/api/visitatie/aangewezenmedewerkers`, request)
      .pipe(
        tap(() => {
          this.store.update(state => {
            const index = state.medewerkerVragen.findIndex(m => m.functie === functie);
            if (index >= 0) {
              state.medewerkerVragen[index].email = email;
              return state = { ...state };
            } else {
              state = { ...state, medewerkerVragen: state.medewerkerVragen };
            }
          });
        })
      );
  }

  public SetVisitatieStatusBespreken(visitatieId: string): Observable<any> {
    return this.setVisitatieStatus(visitatieId, VisitatieStatus.bespreken);
  }

  public UpdateOnderwerpen(updatedOnderwerp: Onderwerp) {

    this.store.update(v => produce(v, draftVisitatie => {
      const indexofOnderwerp = draftVisitatie.hoofdVragen.findIndex(o => o.key === updatedOnderwerp.key);
      draftVisitatie.hoofdVragen[indexofOnderwerp] = updatedOnderwerp;
    }));

    const request = {
      onderwerp: updatedOnderwerp,
    };

    this.client.put(`${this.baseApiUrl}/api/visitatie/UpdateOnderwerp`, request)
    .pipe(first())
    .subscribe();
  }

  public setVisitatieStatus(id: string, status: VisitatieStatus): Observable<any> {
    const request: ISetVisitatieStatusRequest = { visitatieId: id, status: status };

    return this.client.post(`${this.baseApiUrl}/api/visitatie/SetVisitatieStatus`, request);
  }

  public CreateVisitatie(request: ICreateVisitatieRequest): Observable<any> {
    return this.client
      .post(`${this.baseApiUrl}/api/visitatie/CreateVisitatie`, request);
  }

  public GetAllVisitaties(): Observable<Visitatie[]> {
    return this.client.get(`${this.baseApiUrl}/api/visitatie/GetAllVisitaties`)
      .pipe(
        map((visitaties: any[]) => visitaties
          .map(visitatie => this.CreateVisitatieFromObject(visitatie))
          .sort((a, b) => a.visitatieDatum.getTime() - b.visitatieDatum.getTime()))
      );
  }

  public GetTeBesprekenVisitaties(): Observable<Visitatie[]> {
    return this.client.get<Visitatie[]>(`${this.baseApiUrl}/api/visitatie/GetTeBesprekenVisitaties`)
    .pipe(
      tap((visitaties: Visitatie[]) => {
        visitaties.forEach(visitatie => {
          const visiteurVragen = produce(visitatie.visiteurVragen, draft => {
            draft.forEach((draftonderwerp) => {
              draftonderwerp.groepen.forEach((draftgroep) => {
                draftgroep.vragen = draftgroep.vragen.map((draftvraag) => {
                  if ((draftvraag as KeuzeVraag).opties !== undefined || null) {
                    return new KeuzeVraag(draftvraag);
                  } else {
                    return new OpenVraag(draftvraag);
                  }
                });
              });
            });
          });

          visitatie.visiteurVragen = visiteurVragen;
        });

      })
    );
  }

  UpdateVisitatie(onderwerpen: Onderwerp[], ziekenhuis: string) {
    const request = {
      onderwerpen: onderwerpen,
      ziekenhuis: ziekenhuis
    };
    this.client
      .put(`${this.baseApiUrl}/api/visitatie/updatevisitatie`, request)
      .subscribe();
  }

  CreateVisitatieFromObject(obj: any): Visitatie {
    return new Visitatie({
      id: obj['id'] as string,
      ziekenhuisNaam: obj['ziekenhuisNaam'] as string,
      visitatieDatum: new Date(obj['visitatieDatum']),
      vorigeVisitatieDatum: new Date(obj['vorigeVisitatieDatum']),
      kwaliteitcommissie: obj['kwaliteitcommissie'] as string[],
      visitatiecommissie: (obj['visitatiecommissie'] as {}[]).map(o => this.CreateGebruiker(o)),
      status: obj['status']
    });
  }

  CreateGebruiker(obj: any): Gebruiker {
    return new Gebruiker({
      naam: obj['naam'],
      email: obj['email'],
      id: obj['id'],
      rol: obj['rol'],
      ziekenhuis: obj['ziekenhuis'],
    });
  }
}

interface ISetVisitatieStatusRequest {
  visitatieId: string;
  status: VisitatieStatus;
}

export interface ICreateVisitatieRequest {
  ziekenhuisNaam: string;
  visitatieDatum: Date;
  vorigeVisitatieDatum: Date;
  visitatieCommissie: Gebruiker[];
  kwaliteitCommissie: string[];
}
