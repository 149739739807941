import { Component, OnInit, OnDestroy } from '@angular/core';
import { VragenmedewerkerService } from '../../services/vragenmedewerker.service';
import { Subscription, Observable } from 'rxjs';
import { map, tap, debounceTime, first, concatMap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { OnderwerpQuery } from '../../data/onderwerp.query';
import produce from 'immer';
import { Onderwerp } from '../../models/onderwerp';
import { VraagMapper } from '../../mappers/vraag.mapper';
import { KeuzeVraag } from '../../models/keuzevraag';
import { OpenVraag } from '../../models/openvraag';

@Component({
  selector: 'nhv-vragenmedewerker',
  templateUrl: './vragenmedewerker.component.html',
  styleUrls: ['./vragenmedewerker.component.css']
})
export class VragenMedewerkerComponent implements OnInit, OnDestroy {

  public typing = false;
  public form: FormGroup;
  public formSub: Subscription;
  public onderwerpen: Onderwerp[];
  public verstuurd = false;

  constructor(
    private service: VragenmedewerkerService,
    private query: OnderwerpQuery,
    private mapper: VraagMapper) { }

  ngOnInit() {
    this.loadQuestions();
  }

  private loadQuestions() {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }

    this.formSub = this.service.LoadVragenMedewerker().subscribe(
      () => {

        return this.query.onderwerpen$
        .pipe(
          first(),
          concatMap((onderwerpenBugged) => {
            const onderwerpen = this.makeCopy(onderwerpenBugged);
            this.form = this.mapper.FromOnderwerpen(produce(onderwerpen, () => { }));
            this.onderwerpen = produce(onderwerpen, () => { });

            return this.form.valueChanges.pipe(
              tap(() => this.typing = true),
              debounceTime(1000),
              map(formValues => {
                return produce(this.makeCopy(this.onderwerpen), draft =>  {
                  this.onderwerpen.forEach((onderwerp, oi) => {
                    onderwerp.groepen.forEach((groep, gi) => {
                      groep.vragen.forEach((vraag, vi) => {
                        draft[oi].groepen[gi].vragen[vi].antwoord = formValues[onderwerp.key][groep.key][vraag.key];
                      });
                    });
                  });
                });
              }),
              tap(o => this.service.UpdateVragenMedewerker(o)),
              tap(() => this.typing = false)
            );
          })
        ).subscribe();

      }
    );
  }

  private makeCopy(onderwerpen: Array<Onderwerp>): Array<Onderwerp> {

    return produce(onderwerpen, draft => {
      draft.forEach(draftonderwerp => {
        draftonderwerp.groepen.forEach(draftgroep => {
          draftgroep.vragen = draftgroep.vragen.map(draftvraag => {
            if ((draftvraag as KeuzeVraag).opties !== undefined || null) {
              return new KeuzeVraag(draftvraag);
            } else {
              return new OpenVraag(draftvraag);
            }
          });
        });
      });
    });
  }

  public versturen(): any {
    this.service.SaveVragenMedewerker()
      .pipe(
        first(),
        tap(_ => this.loadQuestions()))
      .subscribe(() => {
        this.verstuurd = true;
      });
  }

  ngOnDestroy(): void {
    if (this.formSub) {
      this.formSub.unsubscribe();
    }
  }

}
