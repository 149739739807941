import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IdentityStore } from './identity.store';
import { Identity } from '../models/identity.model';

@Injectable({ providedIn: 'root' })
export class IdentityQuery extends Query<Identity> {

  constructor(protected store: IdentityStore) {
    super(store);
  }

  // let this toggle to false if the time changes
  authStatus$ = this.select(i => i.authStatus);
  jwt$ = this.select(i => i.jwt);
  rol$ = this.select(i => i.rol);
  ziekenhuis$ = this.select(i => i.ziekenhuis);
}
