import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, finalize, tap, map } from 'rxjs/operators';
import produce from 'immer';
import { persistState } from '@datorama/akita';
import { Router } from '@angular/router';
import { VisitatieQuery } from 'src/app/data/visitatie.query';
import { VisitatieService } from 'src/app/services/visitatie.service';
import { Visitatie } from 'src/app/models/visitatie';
import { MedewerkerFunctie } from 'src/app/models/MedewerkerFunctie';

@Component({
  selector: 'app-formulier-ziekenhuis',
  templateUrl: './formulier-ziekenhuis.component.html',
  styleUrls: ['./formulier-ziekenhuis.component.css']
})
export class FormulierZiekenhuisComponent implements OnInit {

  public medewerkerVragen$: Observable<{ email: string, functie: string, disabled: boolean }[]>;
  public hoofdVragen$: Observable<{titel: string, ingevuld: boolean, key: string}[]>;

  public visitatie$: Observable<Visitatie>;
  public loading: boolean;
  public selectedTask: string;
  public vragenIngevuld$: Observable<boolean>;
  public heeftVisitatie$: Observable<boolean>;
  public medewerkerVragenBeantwoord$: Observable<boolean>;

  constructor(
    private query: VisitatieQuery,
    private service: VisitatieService,
    private router: Router) { }

  ngOnInit() {
    this.service.Initiate().pipe(
      tap(() => {
        this.visitatie$ = this.query.select();

        this.heeftVisitatie$ = this.query.select().pipe(map(obj => Object.entries(obj).length > 0 ));
        this.medewerkerVragenBeantwoord$ = this.query.medewerkerVragenBeantwoord$;
        this.vragenIngevuld$ = this.query.select()
        .pipe(
          map(v => {
            return v.hoofdVragen.every(vi => vi.ingevuld);
          }));

        this.hoofdVragen$ = this.query.hoofdVragen$.pipe(
          map(vragen => vragen.map(onderwerp => ({titel: onderwerp.titel , ingevuld: onderwerp.ingevuld, key: onderwerp.key})))
        );

        this.medewerkerVragen$ = this.query.medewerkerVragen$
          .pipe(map(medewerkers => {


            const toegewezenFuncties = medewerkers && medewerkers
              .map((mw) => {
                return {
                  email: mw.medewerker ? mw.medewerker.email : '',
                  functie: MedewerkerFunctie[mw.functie],
                  disabled: mw.ingevuld
                };
              });

            const toeTeWijzenFuncties = Object.keys(MedewerkerFunctie)
              .filter(functienaam => isNaN(Number(functienaam)))
              .map(naam => ({ email: '', functie: naam, disabled: false }))
              .filter(f => !toegewezenFuncties || !toegewezenFuncties.find(t => t.functie === f.functie));

              return produce(toegewezenFuncties, (bestaandeFuncties) => {
                Array.prototype.push.apply(bestaandeFuncties, toeTeWijzenFuncties);
              });
          }));
      }))
      .subscribe();
  }

  verzenden() {
    return this.query.id$.pipe(
      switchMap(id => this.service.SetVisitatieStatusBespreken(id)),
      finalize(() => this.visitatie$ = null)
    ).subscribe(() => {
      persistState().clearStore('visitatie');
      this.service.Initiate(true);

      //todo: fix with better solution, want the visitatie to be reset.
      window.location.reload();
    });
  }

  updateMedewerker(event: { email: string, functieNaam: string }) {
    return this.query.id$.pipe(
      tap(c => console.error(c)),
      switchMap(id => this.service.AddAangewezenMedewerkers(id, event.email, MedewerkerFunctie[event.functieNaam]))
    ).subscribe();
  }
}
