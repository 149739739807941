<div [formGroup]="form">
  <ng-container *ngIf="!vraag.multiline && inputType !== 'date'">
    <mat-form-field class="u-full-width">
      <mat-icon matPrefix *ngIf="inputType === 'email'">email</mat-icon>
      <mat-icon matPrefix *ngIf="inputType === 'tel'">phone</mat-icon>
      <input
        matInput
        [placeholder]="vraag.label"
        [formControlName]="vraag.key"
        [id]="vraag.key"
      />
      <mat-error *ngIf="invalidPattern">
          <mat-icon>error_outline</mat-icon> {{ vraag.validatieMelding }}
      </mat-error>
      <mat-error *ngIf="invalidEmail">
          <mat-icon>error_outline</mat-icon> Ongeldig emailadres
      </mat-error>
      <mat-error *ngIf="isRequired">
        <mat-icon>error_outline</mat-icon> {{ vraag.label }} is <strong>verplicht</strong>
      </mat-error>
      <mat-hint *ngIf="vraag.verplicht">(verplicht)</mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!vraag.multiline && inputType === 'date'">
    <mat-form-field class="u-full-width">
      <input
        matInput
        [matDatepicker]="picker"
        [placeholder]="vraag.label"
        disabled="true"
        [formControlName]="vraag.key"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false"></mat-datepicker>
      <mat-error *ngIf="isRequired">
        {{ vraag.label }} is <strong>verplicht</strong>
      </mat-error>
      <mat-hint *ngIf="vraag.verplicht">(verplicht)</mat-hint>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="vraag.multiline">
    <mat-form-field class="u-full-width">
      <textarea
        matInput
        [placeholder]="vraag.label"
        [formControlName]="vraag.key"
        [id]="vraag.key"

        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="5"

      ></textarea>
      <mat-error *ngIf="isRequired">
        {{ vraag.label }} is <strong>verplicht</strong>
      </mat-error>
      <mat-hint *ngIf="vraag.verplicht">(verplicht)</mat-hint>
    </mat-form-field>
  </ng-container>
</div>
