import { AntwoordType } from './antwoordtype';

export class OpenVraag {

  public key: string;
  public label: string;
  public antwoord: string;
  public verplicht: boolean;
  public regexValidatie: string;
  public validatieMelding: string;
  public multiline: boolean;
  public volgorde: number = 0;
  public antwoordType: AntwoordType;

  constructor(obj: Partial<OpenVraag>) {
    Object.assign(this, obj);
  }
}
