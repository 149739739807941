import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { mergeAll, map } from "rxjs/operators";
import { GebruikersRol } from "../models/gebruikersrol";
import { Gebruiker } from "../models/gebruiker";
import { BASE_API_URL } from "../constants";

@Injectable()
export class GebruikersService {
  constructor(
    private client: HttpClient,
    @Inject(BASE_API_URL) private baseApiUrl
  ) {}

  public getGebruikers(): Observable<Gebruiker[]> {
    return this.client
      .get<Gebruiker[]>(`${this.baseApiUrl}/api/visitatie/gebruikers`)
      .pipe(
        map((col) =>
          col.map(
            (obj) =>
              new Gebruiker({
                naam: obj["naam"],
                email: obj["email"],
                id: obj["id"],
                rol: obj["rol"],
                ziekenhuis: obj["ziekenhuis"],
              })
          )
        )
      );
  }

  public addGebruiker(gebruiker: {
    naam: string;
    email: string;
    rol?: GebruikersRol;
    ziekenhuis: string;
  }): Observable<any> {
    return this.client.post(
      `${this.baseApiUrl}/api/visitatie/gebruiker`,
      gebruiker
    );
  }

  public editGebruiker(gebruiker: {
    naam: string;
    email: string;
    rol?: number;
    ziekenhuis: string;
  }): Observable<any> {
    return this.client.patch(
      `${this.baseApiUrl}/api/visitatie/gebruiker`,
      gebruiker
    );
  }

  notifyBeheerders(): Observable<any> {
    return this.client.get(`${this.baseApiUrl}/api/visitatie/notifybeheerders`);
  }
}
