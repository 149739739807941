import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, flatMap, first} from 'rxjs/operators';
import produce from 'immer';
import { OnderwerpQuery } from '../data/onderwerp.query';
import { BASE_API_URL } from '../constants';
import { OnderwerpStore } from '../data/onderwerp.store';
import { KeuzeVraag } from '../models/keuzevraag';
import { OpenVraag } from '../models/openvraag';
import { Onderwerp } from '../models/onderwerp';

@Injectable({
  providedIn: 'root'
})
export class VragenmedewerkerService {

  constructor(
    private client: HttpClient,
    private store: OnderwerpStore,
    private query: OnderwerpQuery,
    @Inject(BASE_API_URL) private baseApiUrl) {
  }

  public LoadVragenMedewerker(): Observable<any> {
    return this.client
      .get<GetMedewerkerVragen>(`${this.baseApiUrl}/api/visitatie/medewerkervragen`)
      .pipe(
        tap((response: GetMedewerkerVragen) => {

          const onderwerpen = produce(response.onderwerpen, draft => {
            draft.forEach((draftonderwerp) => {
              draftonderwerp.groepen.forEach((draftgroep) => {
                draftgroep.vragen = draftgroep.vragen.map((draftvraag) => {
                  if ((draftvraag as KeuzeVraag).opties !== undefined || null) {
                    return new KeuzeVraag(draftvraag);
                  } else {
                    return new OpenVraag(draftvraag);
                  }
                });
              });
            });
          });
          this.store.set(onderwerpen);
        })
      );
  }

  public UpdateVragenMedewerker(onderwerpen: Array<Onderwerp>) {

    this.store.set(onderwerpen);

    const request = {
      onderwerpen: onderwerpen,
    };

    this.client.put(`${this.baseApiUrl}/api/visitatie/medewerkervragen`, request)
    .pipe(first())
    .subscribe();
  }

  SaveVragenMedewerker(): Observable<any> {

    this.store.update(null, {ingevuld : true});
    return this.query.onderwerpen$.pipe(
      flatMap(onderwerpen => {
        const request = {onderwerpen: onderwerpen};
        return this.client.put(`${this.baseApiUrl}/api/visitatie/medewerkervragen`, request);
      })
    );
  }
}

class GetMedewerkerVragen {
  onderwerpen: Onderwerp[];
}
