import { Component, OnInit } from '@angular/core';
import { VisitatieService, ICreateVisitatieRequest } from '../../services/visitatie.service';
import { Visitatie } from '../../models/visitatie';
import { Router } from '@angular/router';
import { first, filter, map } from 'rxjs/operators';
import { GebruikersService } from '../../services/gebruikers.service';
import { Observable } from 'rxjs';
import { Gebruiker } from '../../models/gebruiker';
import { GebruikersRol } from '../../models/gebruikersrol';

@Component({
  selector: 'nhv-add-visitatie',
  templateUrl: './add-visitatie.component.html',
  styleUrls: []
})
export class AddVisitatieComponent implements OnInit {

  public visitaties$: Observable<Visitatie[]>;
  public Ziekenhuizen = [];
  public Visiteurs: Gebruiker[] = [];
  public selectedVisiteurs: Gebruiker[] = [];
  public selectedZiekenhuis: string;
  public visitatieDatum: Date;
  public foutmelding: string;
  public vorigeVisitatieDatum: Date;
  public kwaliteitcomissie: string = "";

  constructor(
    private visitatieService: VisitatieService,
    private gebruikersService: GebruikersService,
    private router: Router) { }

  ngOnInit() {
    this.visitaties$ = this.visitatieService.GetAllVisitaties();

    this.gebruikersService.getGebruikers()
      .pipe(
        map(gebruikers => gebruikers.filter(g => g.rol && g.rol === GebruikersRol.Visiteur))
      )
      .subscribe(gebruikers => this.Visiteurs = gebruikers);

    this.gebruikersService.getGebruikers()
      .pipe(
        map(gebruikers => gebruikers.filter(g => g.rol && g.rol === GebruikersRol.Hoofd).map(g => g.ziekenhuis))
      )
      .subscribe(ziekenhuizen => this.Ziekenhuizen = ziekenhuizen);

  }

  SetVisitatieDatum(datum: Date) {
    this.visitatieDatum = datum;
  }

  SetVorigeVisitatieDatum(datum: Date) {
    this.vorigeVisitatieDatum = datum;
  }

  SetVisitatiecomissie(ids: string[]) {
    this.selectedVisiteurs = this.Visiteurs.filter(v => ids.includes(v.id));
  }

  SetKwaliteitcomissie(value: string) {
    this.kwaliteitcomissie = value;
  }


  get IsInvalid(): boolean {
    return !this.selectedZiekenhuis || !this.visitatieDatum || !this.vorigeVisitatieDatum || !this.kwaliteitcomissie;
  }

  registreer() {
    if (this.IsInvalid) {
      this.foutmelding = "niet alle velden zijn ingevuld";
    }
    else {
      let request: ICreateVisitatieRequest = {
        ziekenhuisNaam: this.selectedZiekenhuis,
        visitatieDatum: this.visitatieDatum,
        vorigeVisitatieDatum: this.vorigeVisitatieDatum,
        kwaliteitCommissie: this.kwaliteitcomissie.split('\n'),
        visitatieCommissie: this.selectedVisiteurs
      };

      this.visitatieService.CreateVisitatie(request)
        .pipe(first()) // will unsubscribe after first value is yielded.
        .subscribe(() => this.router.navigate(['/visitaties']));
    }
  }
}
