import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderQuery } from 'src/app/data/loader.query';

@Component({
    selector: 'nhv-loader',
    template: `<div *ngIf="loading$ | async" class="loader"><mat-progress-bar mode="indeterminate"></mat-progress-bar></div>`,
    styles: [`.loader {position: fixed; left: 0px; right: 0px; bottom: 0px;}`]
})
export class LoaderComponent implements OnInit {

  public loading$: Observable<Boolean>;

  constructor(private loaderQuery: LoaderQuery) { }

  ngOnInit(): void {
      this.loading$ = this.loaderQuery.loading$;
      }
}
