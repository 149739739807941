import { GebruikersRol } from './gebruikersrol';

export class Gebruiker {
  public naam: string;
  public email: string;
  public id: string;
  public rol?: GebruikersRol;
  public ziekenhuis: string;

  constructor(obj: Partial<Gebruiker>) {
      Object.assign(this, obj);
  }
}
