<div>
<form class="example-form">
  <mat-form-field class="example-full-width">
      <input matInput (keyup)="zoek($event.target.value)" placeholder="zoek gebruiker">
      <mat-icon matSuffix> search </mat-icon>
  </mat-form-field>
</form>
</div>
<table mat-table [dataSource]="dataSource" matSort class="u-full-width">
    <ng-container matColumnDef="naam">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> naam </th>
        <td mat-cell *matCellDef="let row"> {{row.naam}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> email </th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
    </ng-container>
    <ng-container matColumnDef="rol">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> rol </th>
        <td mat-cell *matCellDef="let row"> {{GebruikersRol[row.rol]}} </td>
    </ng-container>
    <ng-container matColumnDef="ziekenhuis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ziekenhuis </th>
        <td mat-cell *matCellDef="let row"> {{row.ziekenhuis}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
            <a mat-button color="primary" [routerLink]="[row.id ,'wijzigen']">
                <mat-icon>edit</mat-icon> wijzig
            </a>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
</table>
<div class="u-spacer-small"></div>
<button mat-raised-button color="primary" [routerLink]="['/gebruikers/toevoegen']">
  gebruiker toevoegen
</button>
