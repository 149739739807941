import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IdentityQuery } from '../data/identity.query';
import { GebruikersRol } from '../models/gebruikersrol';

@Injectable()
export class AuthorizedGuard implements CanActivate {
    constructor(private identityQuery: IdentityQuery) { }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const routeRequiredRole: GebruikersRol = route.data['rol'];
        if (routeRequiredRole) {
            return this.identityQuery.rol$
                .pipe(
                    tap(actualRol => {
                        if (!(actualRol === routeRequiredRole)) {
                            console.log(`Gebruiker niet geauthoriseerd, required role: ${routeRequiredRole} actual role: ${actualRol}`);
                        }
                    }),
                    map(actualRol => actualRol === routeRequiredRole),
                );
        } else {
            console.log('AuthorizeGuard was added to route but no required role was provided');
            return true;
        }
    }
}
