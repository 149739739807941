import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdentityQuery } from '../data/identity.query';
import { concatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private identityQuery: IdentityQuery) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.append('Content-Type', 'application/json')
      });
    }

    if (req.url.startsWith(environment.baseApiUrl) && !req.headers.has('Authorization')) {

      return this.identityQuery.jwt$.pipe(
        concatMap(token => {
          req = req.clone({
            headers: req.headers.append('Authorization', `bearer ${token}`)
          });
          return next.handle(req);
        })
      );

    } else {
      return next.handle(req);
    }
  }
}
