import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GebruikersService } from '../../services/gebruikers.service';
import { Router } from '@angular/router';
import { GebruikersRol } from '../../models/gebruikersrol';

@Component({
  selector: 'nhv-add-gebruikers',
  templateUrl: './add-gebruikers.component.html',
  styleUrls: []
})
export class AddGebruikersComponent implements OnInit {

  public GebruikersRol = GebruikersRol;
  public rollen = Object.keys(GebruikersRol).filter(Number);

  public gebruikerform = this.fb.group({
    naam: [''],
    email: [''],
    rol: [''],
    ziekenhuis: ['']
  });

  public enabled: boolean = true;
  public message: string = null;

  constructor(private fb: FormBuilder, private service: GebruikersService, private router: Router) { }

  ngOnInit(): void { }

  public toevoegen(): void {
    this.enabled = false;

    const gebruiker = {
      naam: this.gebruikerform.value.naam,
      email: this.gebruikerform.value.email,
      rol: Number(this.gebruikerform.value.rol),
      ziekenhuis: this.gebruikerform.value.ziekenhuis
    }

    this.service.addGebruiker(gebruiker).subscribe(
      () => this.router.navigate(['/gebruikers']),
      () => {
        this.message = 'er ging iets fout, probeer het nog een keer.';
        this.enabled = true;
      }
    )
  }
}
