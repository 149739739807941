export enum MedewerkerFunctie {
  'Cardioloog' = 1,
  'Hartfunctielaborant' = 2,
  'Praktijkbegeleider' = 3,
  'Leerling hartfunctie' = 4,
  'Medewerker ECG kamer' = 5,
  'Medewerker ergometrie' = 6,
  'Medewerker echocardiografie' = 7,
  'Medewerker holter analyse' = 8,
  'Medewerker hartkatheterisatie' = 9,
  'Medewerker pacemaker' = 10,
}
