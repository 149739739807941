import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BASE_API_URL } from '../constants';

@Injectable()
export class DocumentenService {

  constructor(
    private client: HttpClient,
    @Inject(BASE_API_URL) private baseApiUrl) { }

  downloadIntake(visitatieId: string): Observable<any> {
    const params = new HttpParams().append('visitatieId', visitatieId);

    return this.client.get(
      `${this.baseApiUrl}/api/visitatie/getintakepdf`,
      { params: params, responseType: 'blob' }
    );
  }

  downloadVisitatie(visitatieId: string): Observable<any> {
    const params = new HttpParams().append('visitatieId', visitatieId);

    return this.client.get(
      `${this.baseApiUrl}/api/visitatie/getvisitatiepdf`,
      { params: params, responseType: 'blob' }
    );
  }

  downloadVerklaring(visitatieId: string): Observable<any> {
    const params = new HttpParams().append('visitatieId', visitatieId);

    return this.client.get(
      `${this.baseApiUrl}/api/visitatie/getsbhflpdf`,
      { params: params, responseType: 'blob' }
    );

  }

}
