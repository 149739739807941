import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LoaderStore, LoaderState } from './loader.store';

@Injectable({ providedIn: 'root' })
export class LoaderQuery extends Query<LoaderState> {

  constructor(protected store: LoaderStore) {
    super(store);
  }

  loading$ = this.select(s => s.loading);
}
