import { Injectable } from '@angular/core';
import { Visitatie } from '../models/visitatie';
import { Store, StoreConfig } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'visitatie' })
export class VisitatieStore extends Store<Visitatie> {

  constructor() {
    super({} as Visitatie);
  }
}
