import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VragenGroep } from '../models/vragengroep';
import { KeuzeVraag } from '../models/keuzevraag';
import { OpenVraag } from '../models/openvraag';
import { AntwoordType } from '../models/antwoordtype';
import { Onderwerp } from '../models/onderwerp';

@Injectable()
export class VraagMapper {

  constructor() { }

  public FromOnderwerpen(input: Onderwerp[]): FormGroup {
    const onderwerpen = {};

    input.forEach(groep => {
      onderwerpen['' + groep.key] = this.FromGroups(groep.groepen);
    });

    return new FormGroup(onderwerpen);
  }

  public FromGroups(input: VragenGroep[]): FormGroup {
    const groups = {};

    input.forEach(groep => {
      groups['' + groep.key] = this.FromVragen(groep.vragen);
    });

    return new FormGroup(groups);
  }

  public FromGroup(input: VragenGroep): FormGroup {
    return this.FromVragen(input.vragen);
  }

  public FromVragen(vragen: Array<KeuzeVraag | OpenVraag>): FormGroup {
    const group: any = {};
    vragen.forEach(vraag => {
      const validaties = [];

      if (vraag.verplicht && (vraag as OpenVraag).regexValidatie) {
        validaties.push(Validators.pattern((vraag as OpenVraag).regexValidatie));
      }
      if (vraag.verplicht) {
        validaties.push(Validators.required);
      }
      if (vraag.verplicht && (vraag as OpenVraag).antwoordType === AntwoordType.Email) {
        validaties.push(Validators.email);
      }

      if (validaties) {
        group[vraag.key] = new FormControl(vraag.antwoord || '', validaties);
      } else {
        group[vraag.key] = new FormControl(vraag.antwoord || '');
      }

    });
    return new FormGroup(group);
  }
}
