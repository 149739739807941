import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, toArray, first, map, tap } from 'rxjs/operators';
import { GebruikersService } from '../../services/gebruikers.service';
import { IdentityQuery } from '../../data/identity.query';
import { Gebruiker } from 'src/app/models/gebruiker';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { GebruikersRol } from 'src/app/models/gebruikersrol';

@Component({
  selector: 'nhv-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: []
})
export class UnauthorizedComponent implements OnInit {

  public geenRol = false;
  beheerders$: Observable<Gebruiker[]>;

  constructor(
    private authService: AuthenticationService,
    private identityQuery: IdentityQuery,
    private gebruikersService: GebruikersService,
    private router: Router) { }

  ngOnInit(): void {
    this.redirect();
  }

  redirect(): any {
    this.identityQuery.rol$.pipe(
      map((rol) => {
        switch (rol) {
          case GebruikersRol.Hoofd:
            return 'intake';
          case GebruikersRol.Visiteur:
            return 'visiteur';
          case GebruikersRol.Beheerder:
            return 'visitaties';
          case GebruikersRol.Medewerker:
            return 'intake/medewerker';
          default:
            return null;
        }
      })
    ).subscribe(route => {
      if (route) {
        this.router.navigate([route]);
      } else {
        this.geenRol = true;
        this.gebruikersService.notifyBeheerders().pipe(first()).subscribe();

        this.beheerders$ = this.gebruikersService.getGebruikers()
        .pipe(
          map(gebruikers => gebruikers.filter(g => g.rol === GebruikersRol.Beheerder && (g.naam !== null || g.naam !== undefined)))
        );
      }
      });
  }
}
