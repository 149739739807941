import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ERROR_COMPONENT_TYPE } from '@angular/compiler';

@Injectable()
export class LoggingSink implements ErrorHandler {

  constructor(
    private client: HttpClient,
    private deviceService: DeviceDetectorService
  ) { }

  handleError(error) {
    var message = {
      device: this.deviceService
    };

    switch (this.deviceService.browser) {
      case "Firefox":
        message = { ...message, ... this.parseFirefoxError(error) };
        break;
      case "Chrome":
        message = { ...message,  ...this.parseChromeError(error) };
        //message = this.parseError(error);
        break;
      case "MS-Edge-Chromium":
        message = { ...message,  ...this.parseEdgeError(error) };
        break;

      default:
        message = { ...message,  ...this.parseError(error) };
        break;
    }

    this.client
      .post(`https://wuru.eu.ngrok.io`, message)
      .subscribe();
  }

  parseFirefoxError(error) {
    return {
      message: error.message.split('\n')[0],
      stack: error.stack.split('\n').filter(l => l).map(stackline => {

        const method = stackline.substring(0, stackline.indexOf('@'));

        const split_file = stackline.substring(stackline.indexOf('@') + 1).split(':');
        const file = split_file.slice(0, split_file.length - 2).join(':');
        const line = +split_file[split_file.length - 2];
        const column = +split_file[split_file.length - 1];

        return { method, file, line, column, original: stackline };
      }),
      originalStack: error.stack
    };
  }

  parseChromeError(error) {
    const messages = error.message.split('\n').filter(x => x.indexOf('Error:') >= 0);

    const stack = error.stack.split('\n').slice(messages.length).map(stackline => {
      if (stackline.indexOf('(') >= 0) {
        const method = stackline.substring(stackline.indexOf('at ') + 3, stackline.indexOf('(') - 1);
        const file = stackline.substring(stackline.indexOf('(') + 1, stackline.indexOf('.js') + 3);
        const split_file = stackline.substring(stackline.indexOf('(') + 1, stackline.indexOf(')')).split(':');
        const line = +split_file[split_file.length - 2];
        const column = +split_file[split_file.length - 1];

        return { method, file, line, column };
      } else {
        //at http://localhost:4200/polyfills.js:20511:21'

        const split_file = stackline.substring(stackline.indexOf('at') + 2).trim().split(':');
        const file = split_file.slice(0, split_file.length - 2).join(':');
        const line = +split_file[split_file.length - 2];
        const column = +split_file[split_file.length - 1];

        return { method: "", file, line, column };
      }
    });

    return {
      messages: messages,
      stack: stack,
      originalStack: error.stack
    };
  }

  parseEdgeError(error) {
    const messages = error.message.split('\n').filter(x => x.indexOf('Error:') >= 0);

    const stack = error.stack.split('\n').slice(messages.length).map(stackline => {
      if (stackline.indexOf('(') >= 0) {
        const method = stackline.substring(stackline.indexOf('at ') + 3, stackline.indexOf('(') - 1);
        const file = stackline.substring(stackline.indexOf('(') + 1, stackline.indexOf('.js') + 3);
        const split_file = stackline.substring(stackline.indexOf('(') + 1, stackline.indexOf(')')).split(':');
        const line = +split_file[split_file.length - 2];
        const column = +split_file[split_file.length - 1];

        return { method, file, line, column };
      } else {
        //at http://localhost:4200/polyfills.js:20511:21'

        const split_file = stackline.substring(stackline.indexOf('at') + 2).trim().split(':');
        const file = split_file.slice(0, split_file.length - 2).join(':');
        const line = +split_file[split_file.length - 2];
        const column = +split_file[split_file.length - 1];

        return { method: "", file, line, column };
      }
    });

    return {
      messages: messages,
      stack: stack,
      originalStack: error.stack
    };
  }

  parseError(error) {
    return {
      message: error.message.split('\n')[0],
      stack: error.stack.split('\n')
    };
  }

}
