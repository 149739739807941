import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public pagina$: Observable<string>;

  constructor(private service: ContentService) { }

  ngOnInit(): void {
    this.pagina$ = this.service.getContact();
  }

}
