import { Component, OnInit } from '@angular/core';
import { GebruikersService, } from '../../services/gebruikers.service';
import { Subscription, Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Gebruiker } from '../../models/gebruiker';
import { GebruikersRol } from '../../models/gebruikersrol';

@Component({
    selector: 'nhv-list-gebruikers',
    templateUrl: './list-gebruikers.component.html',
    styleUrls: []
})
export class ListGebruikersComponent implements OnInit {

    // nodig om de string waarde van een enum te laten zien
    GebruikersRol = GebruikersRol;

    public gebruikers: Gebruiker[] = [];
    public dataSource: MatTableDataSource<Gebruiker>;
    public displayedColumns: string[] = ['naam', 'email', 'rol', 'ziekenhuis', 'actions'];


    private subscriptions: Subscription[] = [];

    constructor(private service: GebruikersService) { }

    ngOnInit(): void {
      this.subscriptions.push(
        this.service.getGebruikers()
        .subscribe((gebruikers) => {
          this.dataSource = new MatTableDataSource(gebruikers);
        })
      );
    }

    zoek(input: string) {
      this.dataSource.filter = input.trim().toLowerCase();
    }
}
