import { Component, OnInit, OnDestroy } from '@angular/core';
import { map, defaultIfEmpty } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GebruikersRol } from './models/gebruikersrol';
import { IdentityQuery } from './data/identity.query';
import { AuthenticationService } from './services/Authentication.service';
import { ContentService } from './services/content.service';
import { faSignInAlt, faSignOutAlt, faHome } from '@fortawesome/free-solid-svg-icons';
import { AuthStatus } from './models/authstatus.model';

@Component({
  selector: 'nhv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  faHome = faHome;
  faSignOutAlt = faSignOutAlt;
  faSignInAlt = faSignInAlt;

  public title = 'visitatie registratie';
  public rol$: Observable<GebruikersRol>;
  public authenticated$: Observable<boolean>;
  public GebruikersRol = GebruikersRol;
  public supportedBrowsers = ['Chrome', 'Edge', 'Firefox'];


  constructor(
    private identityQuery: IdentityQuery,
    private authService: AuthenticationService,
    private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.rol$ = this.identityQuery.rol$;
    this.authenticated$ = this.identityQuery.authStatus$.pipe(map(x => x === AuthStatus.Authenticated ));
  }


  public get supportedBrowser(): boolean {
    const notSupportedBrowsers = ['IE'];
    return notSupportedBrowsers.indexOf(this.deviceService.browser) < 0;
  }

  public get currentUrl(): string {
    return window.location.href;
  }


  loguit(): void {
    this.authService.logout();
  }
}
