import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { IdentityQuery } from '../data/identity.query';
import { Observable, of } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { AuthStatus } from '../models/authstatus.model';
import { AuthenticationService } from '../services/Authentication.service';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(private identityQuery: IdentityQuery, private authService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.identityQuery.authStatus$.pipe(first(), switchMap(status => {
        switch (status) {
          case AuthStatus.Authenticated:
            return of(true);

          case AuthStatus.Authenticating:
            return this.authService.parseToken();

          case AuthStatus.Unauthenticated:
            this.authService.login();
            return of(false);

          default:
            return of(false);
        }
      }));
    }
  }
