import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Visitatie } from '../models/visitatie';
import { VisitatieStore } from './visitatie.store';
import { Observable } from 'rxjs';
import { Onderwerp } from '../models/onderwerp';

@Injectable({ providedIn: 'root' })
export class VisitatieQuery extends Query<Visitatie> {

  constructor(protected store: VisitatieStore) {
    super(store);
  }

  hoofdVragen$: Observable<Onderwerp[]> = this.select(v => v.hoofdVragen);
  medewerkerVragen$ = this.select(v => v.medewerkerVragen);
  medewerkerVragenBeantwoord$ = this.select(v => v.medewerkerVragen.filter(vr => vr.medewerker).every(vr => vr.ingevuld));
  id$ = this.select(v => v.id);
}
