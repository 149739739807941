import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { Observable } from 'rxjs';
import { Nieuws } from 'src/app/models/nieuws';

@Component({
  selector: 'app-nieuws',
  templateUrl: './nieuws.component.html',
  styleUrls: ['./nieuws.component.scss']
})
export class NieuwsComponent implements OnInit {

  public nieuws$: Observable<Nieuws[]>;

  constructor(private service: ContentService) { }

  ngOnInit(): void {
    this.nieuws$ = this.service.getNieuws();
  }

}
