import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.baseApiUrl)) {
      if (!req.headers.has('Cache-Control')) {
        req = req.clone({
          headers: req.headers
            .append('Cache-Control', 'no-cache')
            .append('Pragma', 'no-cache')
            .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            .append('If-Modified-Since', '0'),
        });
      }
    }

    return next.handle(req);
  }
}
