
export class KeuzeVraag {
  controlType = 'dropdown';

  public key: string;
  public label: string;
  public antwoord: string;
  public opties: { [index: string]: string } = {};
  public verplicht: boolean;
  public volgorde: number = 0;

  constructor(obj: Partial<KeuzeVraag>) {
    this.key = obj.key;
    this.label = obj.label;
    this.antwoord = obj.antwoord;
    this.verplicht = obj.verplicht;
    this.volgorde = obj.volgorde;

    if (obj.opties) {
      Object.assign(this.opties, obj.opties);
    }
  }
}
