import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/Authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { map, first, switchMap } from 'rxjs/operators';
import { faHospitalUser, faUserSecret } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  showEmail = false;
  faHospitalUser = faHospitalUser;
  faUserSecret = faUserSecret;


  constructor(
    private service: AuthenticationService,
    private router: Router) { }


  loginZiekenhuis(): void {
    this.showEmail = true;
  }

  loginOTP(): void {
    this.router.navigate(['/otp', this.email.value]);
  }

  loginVisiteur(): void {
    this.service.login();
  }


  ngOnInit() {

  }
}
