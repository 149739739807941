<ng-container *ngIf="(heeftVisitatie$ | async); else geen">
    <ng-container *ngIf="visitatie$ | async as visitatie">
        <div *ngIf="visitatie">
          <mat-tab-group>
            <mat-tab label="vragen">
                <div class="row">
                <div class="six columns">
                    <h2>Vragen aan u</h2>
                    <p>Voordat wij op visitatie komen moet u een aantal vragen beantwoorden. Deze vragen staan hieronder over verschillende onderwerpen verdeeld.</p>
                    <mat-nav-list dense>
                      <a mat-list-item *ngFor="let vragen of (hoofdVragen$ | async)" routerLink="/intake/vragen/{{vragen.key}}">
                        <mat-icon *ngIf="vragen.ingevuld" mat-list-icon>check_circle</mat-icon>
                        <mat-icon *ngIf="!vragen.ingevuld"  mat-list-icon>edit</mat-icon>
                        {{ vragen.titel }}
                      </a>
                    </mat-nav-list>
                  </div>

                  <div class="six columns">
                    <h2>Vragen aan uw medewerkers</h2>
                    <p>Wij hebben ook nog een aantal vragen ter voorbereiding voor uw medewerkers. Hieronder kan u per functie een medewerker opgeven die deze vragen kan beantwoorden.</p>
                    <nhv-medewerker-toewijzing
                      *ngFor="let vraag of (medewerkerVragen$ | async)"
                      [functie]="vraag.functie"
                      [email]="vraag.email"
                      [disabled]="vraag.disabled"
                      [completed]="vraag.disabled"
                      (medewerkertoegewezen)="updateMedewerker($event)">
                    </nhv-medewerker-toewijzing>
                  </div>
                </div>
                <div class="row">
                  <div class="u-spacer-small"></div>
                </div>
                <div class="row">
                  <div class="twelve columns">
                    <mat-card class="warning" *ngIf="!(vragenIngevuld$ | async)">
                      Nog niet alle vragen zijn ingevuld of opgeslagen.
                    </mat-card>
                    <mat-card class="warning" *ngIf="!(medewerkerVragenBeantwoord$ | async)">
                      Nog niet alle aangewezen medewerkers hebben hun vragen beantwoord of opgeslagen.
                    </mat-card>
                  </div>
                </div>
                <div class="row">
                  <div class="u-spacer-small"></div>
                </div>
                <div class="row">
                  <div class="twelve columns">
                    <button mat-raised-button [disabled]="!((vragenIngevuld$ | async) && (medewerkerVragenBeantwoord$ | async))" color="primary" (click)="verzenden()">verzenden naar visiteur</button>
                  </div>
                </div>
            </mat-tab>
            <mat-tab label="visitatie gegevens">
                <div class="row">
                <div class="six columns">
                    <h2>Visitatie gegevens</h2>
                      <p><b>instelling: </b>{{ visitatie.ziekenhuisNaam }}</p>
                      <p><b>vorige visitatie datum: </b>{{ visitatie.vorigeVisitatieDatum | date:'dd-MM-yyyy'}}</p>
                      <p><b>volgende visitatie datum: </b>{{ visitatie.visitatieDatum | date:'dd-MM-yyyy'}}</p>
                      <p><b>namen visitatiecomissie NHV: </b><i *ngFor="let gebruiker of visitatie.visitatiecommissie">{{ gebruiker.naam }} </i></p>
                      <p><b>namen commissie kwaliteit NVVC: </b><i *ngFor="let naam of visitatie.kwaliteitcommissie">{{ naam }} </i></p>
                  </div>
                  </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-container>
</ng-container>
<ng-template #geen>
    <mat-card class="warning">
      Er is nog geen (nieuwe) visitatie gepland. Had u wel een visitatie verwacht? Neem dan aub contact met ons op.
    </mat-card>
</ng-template>


