<div class="login">
  <p class="login-intro">
    Deze portal is bedoeld voor visiteurs of personeel van ziekenhuizen die aan een visitatie deelnemen. Maak een keuze
    hieronder afhankelijk van uw rol.
  </p>

  <mat-card class="login-ziekenhuis">
    <mat-card-header>
      <mat-card-title>
        <fa-icon [icon]="faHospitalUser"></fa-icon> Ziekenhuis
      </mat-card-title>
      <mat-card-subtitle>login als medewerker van ziekenhuis</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>
        Als u bent uitgenodigd om deel te nemen aan een visitatie kan u door middel van uw email inloggen. U ontvangt
        dan een code per email waarmee u vervolgens kan inloggen.
      </p>
      <p class="u-full-width">
        <mat-form-field>
          <input matInput [formControl]="email" placeholder="email" />
        </mat-form-field>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button [disabled]="!email.valid" (click)="loginOTP()" mat-flat-button matSuffix color="primary">
        <mat-icon>verified_user</mat-icon>inloggen
      </button>
    </mat-card-actions>
  </mat-card>

  <mat-card class="login-visiteur">
    <mat-card-header>
      <mat-card-title>
        <fa-icon [icon]="faUserSecret"></fa-icon> Visiteur
      </mat-card-title>
      <mat-card-subtitle>login als visiteur van de NHV</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <p>
        U kan door middel van uw gmail account inloggen als visiteur.
      </p>
    </mat-card-content>

    <mat-card-actions>
      <button (click)="loginVisiteur()" mat-flat-button matSuffix color="primary">
        <mat-icon>verified_user</mat-icon>inloggen
      </button>
    </mat-card-actions>
  </mat-card>
