import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'nhv-medewerker-toewijzing',
  template: `
    <form>
    <mat-form-field class="u-full-width">
      <span matPrefix style="padding-right:10px;">
        <mat-icon *ngIf="completed">check_circle</mat-icon>
        <mat-icon *ngIf="!completed">email</mat-icon>
      </span>
      <input matInput [placeholder]="functie" [formControl]="emailControl">
      <button *ngIf="!completed" (click)="updateMedewerker()" mat-flat-button matSuffix color="secondary" [disabled]="disabled || emailControl.hasError('email')">
        <ng-container *ngIf="!email"><mat-icon>person_add</mat-icon>uitnodigen</ng-container>
        <ng-container *ngIf="email"><mat-icon>person</mat-icon>wijzigen</ng-container>
      </button>
      <mat-error *ngIf="emailControl.hasError('email')">
      Vul aub een geldig E-mailadres adres in.
    </mat-error>
    </mat-form-field>
  </form>
`
})
export class MedewerkerToewijzingComponent implements OnInit, OnChanges {

  @Input() email: string;
  @Input() functie: string;
  @Input() disabled: boolean;
  @Input() completed: boolean;
  @Output() medewerkertoegewezen: EventEmitter<{ email: string, functieNaam: string }> = new EventEmitter();

  emailControl = new FormControl('', [Validators.email]);
  constructor() { }

  ngOnInit() {
    this.emailControl.patchValue(this.email);
    if (this.disabled) {
      this.emailControl.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['email']) {
      this.emailControl.patchValue(changes['email'].currentValue);
    }

    if (changes['disabled']) {
      if (changes['disabled'].currentValue as boolean) {
        this.emailControl.disable();
      } else {
        this.emailControl.enable();
      }
    }
  }

  updateMedewerker() {
    this.email = this.emailControl.value;
    this.medewerkertoegewezen.emit({ email: this.emailControl.value, functieNaam: this.functie });
  }
}
