import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KeuzeVraag } from '../../models/keuzevraag';

@Component({
  selector: 'keuze-vraag',
  templateUrl: './keuze-vraag.component.html',
  styleUrls:['./keuze-vraag.component.css'],
})
export class KeuzeVraagComponent implements OnInit, OnChanges {

  @Input() vraag: KeuzeVraag;
  @Input() form: FormGroup;

  public opties: {key: string, value: string}[] = [] ;

  get isValid() { return this.form.controls[this.vraag.key].valid; }

  get showRadio() {return this.opties.length <= 2; }

  public createOptions() {
    if (this.vraag.opties) {
      this.opties = Object
        .keys(this.vraag.opties)
        .map(key => ({key: key, value: this.vraag.opties[key]}));
    }
  }

  ngOnInit(): void {
    this.createOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['vraag']) {
      this.createOptions();
    }
  }
}
