import { Component, OnInit } from '@angular/core';
import { GebruikersService } from '../../services/gebruikers.service';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { flatMap, map, filter, first, mergeAll } from 'rxjs/operators';
import { GebruikersRol } from '../../models/gebruikersrol';

@Component({
    selector: 'nhv-edit-gebruikers',
    templateUrl: './edit-gebruikers.component.html',
    styleUrls: []
})
export class EditGebruikersComponent implements OnInit {
    public GebruikersRol = GebruikersRol;
    public rollen = Object.keys(GebruikersRol).filter(Number);

    public gebruikerform = this.fb.group({
        naam: { value: '', disabled: true },
        email: { value: '', disabled: true },
        id: [''],
        rol: ['1'],
        ziekenhuis: [''],
    });

    public enabled: boolean;
    public message: string = null;

    constructor(
        private fb: FormBuilder,
        private service: GebruikersService,
        private router: Router,
        private route: ActivatedRoute,
        ) { }

    ngOnInit(): void {
        this.enabled = false;

        this.route.params.pipe(
            map(p => p.id as string),
            flatMap(id => this.service.getGebruikers()
                .pipe(
                    mergeAll(),
                    filter(g => g.id === id),
                    map(gebruiker => {
                        return {
                            naam: gebruiker.naam,
                            email: gebruiker.email,
                            id: gebruiker.id,
                            rol: String(gebruiker.rol),
                            ziekenhuis: gebruiker.ziekenhuis
                        };
                    }),
                    first())),
            first())
            .subscribe(gebruiker => {
                this.gebruikerform.patchValue(gebruiker);
                this.enabled = true;
            });
    }

    public toevoegen(): void {
        this.enabled = false;

        const gebruiker = {
            naam: this.gebruikerform.value.naam,
            email: this.gebruikerform.value.email,
            id: this.gebruikerform.value.id,
            rol: Number(this.gebruikerform.value.rol),
            ziekenhuis: this.gebruikerform.value.ziekenhuis
        };

        this.service.editGebruiker(gebruiker)
            .pipe(first())
            .subscribe(
                null,
                () => {
                    this.message = 'er ging iets fout, probeer het nog een keer.';
                    this.enabled = true;
                },
                () => this.router.navigate(['/gebruikers'])
            );
    }
}
