import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Nieuws } from '../models/nieuws';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ContentService {
  constructor(private httpClient: HttpClient) {}

  public getLinks(): Observable<string> {
    return this.httpClient.get<string>('https://cms.nhvvisitatie.nl/links').pipe(map(x => x['Pagina']));
  }

  public getNieuws(): Observable<Nieuws[]> {
    return this.httpClient.get<Nieuws[]>('https://cms.nhvvisitatie.nl/nieuws');
  }

  public getContact(): Observable<string> {
    return this.httpClient.get<string>('https://cms.nhvvisitatie.nl/contact').pipe(map(x => x['Pagina']));
  }

  public getHome(): Observable<string> {
    return this.httpClient.get<string>('https://cms.nhvvisitatie.nl/home').pipe(map(x => x['Pagina']));
  }

  public getTarieven(): Observable<string> {
    return this.httpClient.get<string>('https://cms.nhvvisitatie.nl/tarieven').pipe(map(x => x['Pagina']));
  }
}
