<h1>Nieuwe Visitatie registreren</h1>

<mat-form-field class="u-full-width">
  <mat-select placeholder="ziekenhuis" [(value)]="selectedZiekenhuis">
    <mat-option *ngFor="let ziekenhuis of Ziekenhuizen" [value]="ziekenhuis">
      {{ziekenhuis}}
    </mat-option>
  </mat-select>
</mat-form-field>

<nhv-question-datum [datum]="visitatieDatum" (change)="SetVisitatieDatum($event)" label="datum visitatie"></nhv-question-datum>

<nhv-question-datum [datum]="vorigeVisitatieDatum" (change)="SetVorigeVisitatieDatum($event)" label="datum vorige visitatie"></nhv-question-datum>

<p>
  <mat-form-field class="u-full-width">
    <mat-select placeholder="Visiteurs" (selectionChange)="SetVisitatiecomissie($event.value)" multiple>
      <mat-option *ngFor="let visiteur of Visiteurs" [value]="visiteur.id">{{visiteur.naam}}</mat-option>
    </mat-select>
  </mat-form-field>
</p>

<p>
  <mat-form-field class="u-full-width">
    <textarea matInput placeholder="namen NVVC kwaliteitcomissie" [value]="kwaliteitcomissie" (change)="SetKwaliteitcomissie($event.target.value)"></textarea>
  </mat-form-field>
</p>

<p *ngIf="foutmelding"> {{foutmelding}} </p>

<button mat-raised-button color="primary" (click)="registreer()">registreren</button>
