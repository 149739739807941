import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap, map, tap, single, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/Authentication.service';

@Component({
  selector: 'nhv-passwordless',
  template: `
  <form>
    <p *ngIf="!toomanycodes">
    Er is naar {{ email$ | async }} een email verstuurd met een code. Vul deze
    code hieronder in om in te loggen.
  </p>
  <p *ngIf="toomanycodes">
    U heeft al een aantal codes ontvangen, als u deze niet terug kan vinden kan u een <a href="javascript:void(0)" (click)="newCode()">nieuwe code aanvragen</a>.
  </p>
    <p *ngIf="error">
    Er is iets fout gegaan, mogelijk is de code verlopen of is hij al gebruikt.
    <a href="javascript:void(0)" (click)="newCode()">vraag een nieuwe code aan</a>
    </p>
    <p class="u-full-width">
      <mat-form-field>
        <input matInput [formControl]="code" placeholder="code" />
        <button (click)="login()" mat-flat-button matSuffix color="primary">
          <mat-icon>verified_user</mat-icon>inloggen
        </button>
      </mat-form-field>
    </p>
  </form>
  `,
  styleUrls: []
})
export class PasswordlessComponent implements OnInit {

  email$: Observable<string>;

  error: boolean = false;

  code = new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]);

  toomanycodes = false;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {

    this.email$ = this.route.paramMap.pipe(
      map(params => params.get('email'))
    );

    this.email$
      .pipe(first())
      .subscribe(email => {

      this.authenticationService.GetCodesCount(email)
        .pipe(first())
        .subscribe(count => {

          if (count < 5) {

            this.authenticationService
            .sendCode(email)
            .pipe(first())
            .subscribe();

          } else {
            this.toomanycodes = true;
          }

        });

    });
  }

  public login() {
    this.email$.subscribe(email => {
      this.authenticationService.VerifyCode(email, this.code.value);
    });
  }

  public newCode() {
    this.error = false;
    this.toomanycodes = false;

    this.email$.pipe(
      switchMap(email => this.authenticationService.sendCode(email)),
    ).subscribe();
  }
}
