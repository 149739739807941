import { Injectable, InjectionToken, Inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { IdentityStore } from '../data/identity.store';
import { AuthStatus } from '../models/authstatus.model';
import { CookiesService } from './cookie.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { persistState } from '@datorama/akita';
import { BASE_API_URL, BASE_WEBSITE_URL } from '../constants';
import { GebruikersRol } from '../models/gebruikersrol';
import { Router } from '@angular/router';

(window as any).global = window;

export const storage = persistState();

@Injectable()
export class AuthenticationService {


  constructor(
    private client: HttpClient,
    private cookie: CookiesService,
    @Inject(BASE_API_URL) private baseApiUrl,
    @Inject(BASE_WEBSITE_URL) private websiteUrl,
    private identitymoduleStore: IdentityStore,
    private router: Router
  ) { }

  public login(): void {
    this.identitymoduleStore.update(state => state = {
      ...state,
      authStatus: AuthStatus.Authenticating
    });

    const provider = 'Google';
    const url = `${this.baseApiUrl}/api/authenticatie/ExternalLogin?provider=${provider}`;
    document.location.href = url;
  }

  public parseToken(): Observable<boolean> {
    return Observable.create(obs => {
      const token = this.cookie.getCookie('token');
      if (!token) {

        this.identitymoduleStore.update(state => state = {
          ...state,
          authStatus: AuthStatus.Unauthenticated,
        });

        obs.next(false);

      } else {
        const jwtHelper = new JwtHelperService();
        const claims = jwtHelper.decodeToken(token);

        this.identitymoduleStore.update(state => state = {
          ...state,
          expires: jwtHelper.getTokenExpirationDate(token),
          id: claims.id,
          ziekenhuis: claims.ziekenhuis,
          rol: GebruikersRol[claims.rol as string],
          authStatus: AuthStatus.Authenticated,
          jwt: token
        });

        this.cookie.deleteCookie('token', '.nhvvisitatie.nl', '/');
        obs.next(true);
      }
      obs.complete();
    });
  }

  public GetCodesCount(email: string): Observable<number> {
    const httpOptions = {
      params: new HttpParams().append('email', email)
    };

    return this.client.get<number>(`${this.baseApiUrl}/api/authenticatie/codescount`, httpOptions);
  }

  public logout(): void {

    //todo: onderstaande is een wanhopige fix. Ergens is nog een subscription die de store weer update.
    this.identitymoduleStore.reset();

    storage.destroy(); // Stop sync the state
    storage.clearStore(); // Clear the storage

    localStorage.setItem('akita', '0');
    localStorage.removeItem('akita');
    localStorage.clear();

    this.cookie.deleteCookie('token', '.nhvvisitatie.nl', '/');

    this.router.navigate(['']);
  }

  public sendCode(email: string): Observable<any> {
    const httpOptions = {
      params: new HttpParams()
        .append('email', email)
    };

    return this.client.get(`${this.baseApiUrl}/api/authenticatie/SendOneTimePassword`, httpOptions);
  }

  public VerifyCode(email: string, code: string): void {

    this.identitymoduleStore.update(state => state = {
      ...state,
      authStatus: AuthStatus.Authenticating
    });

    const url = `${this.baseApiUrl}/api/authenticatie/VerifyOneTimePassword?email=${email}&password=${code}`;
    document.location.href = url;
  }
}
