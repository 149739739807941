<div [formGroup]="form">
  <ng-container *ngIf="showRadio">
      <label class="u-full-width radio-label">{{ vraag.label }}</label>
      <mat-radio-group [formControlName]="vraag.key" class="u-full-width radio">
        <mat-radio-button *ngFor="let opt of opties" [value]="opt.key">
          {{ opt.value }}
        </mat-radio-button>
      </mat-radio-group>
      <div class="u-full-width">
        <mat-hint *ngIf="vraag.verplicht" align="end">(verplicht)</mat-hint>
      </div>
    </ng-container>

  <mat-form-field *ngIf="!showRadio" class="u-full-width">
    <mat-select
      [id]="vraag.key"
      [placeholder]="vraag.label"
      [formControlName]="vraag.key"
      class="u-full-width"
    >
      <mat-option *ngFor="let opt of opties" [value]="opt.key">
        {{ opt.value }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="vraag.verplicht">(verplicht)</mat-hint>
  </mat-form-field>
</div>
