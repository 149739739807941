<ng-container *ngIf="onderwerpen && onderwerpen.length > 0; else geen">
  <div class="row">
    <div class="twelve columns">
      <h1>Vragen aan u</h1>
      <p>
        Als voorbereiding van de visitatie op de afdeling door de NHV willen wij graag dat u enkele vragen beantwoord. Alvast bedankt voor uw medewerking.
      </p>
    </div>
  </div>
  <form (ngSubmit)="versturen()" [formGroup]="form">
    <ng-container *ngFor="let onderwerp of onderwerpen">
      <div class="row">
        <div class="twelve columns">
          <h2>{{ onderwerp.titel }}</h2>
          <p>{{ onderwerp.beschrijving }}</p>
        </div>
      </div>
      <ng-container *ngFor="let groep of onderwerp.groepen">
        <div class="row" *ngIf="onderwerp.groepen.length > 1">
          <div class="twelve columns">
              <h3> {{ groep.titel }}</h3>
              <p> {{ groep.beschrijving }}</p>
          </div>
        </div>
        <div class="row">
          <div class="twelve columns">
            <ng-container *ngFor="let vraag of groep.vragen">
                <open-vraag   *ngIf="vraag | instanceof: 'openvraag'" [vraag]="vraag" [form]="form.get(onderwerp.key).get(groep.key)"></open-vraag>
                <keuze-vraag  *ngIf="vraag | instanceof: 'keuzevraag'" [vraag]="vraag" [form]="form.get(onderwerp.key).get(groep.key)"></keuze-vraag>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="verstuurd">
      <div class="row">
        <div class="twelve columns">
            <mat-card class="warning">Bedankt voor het beantwoorden van de vragen. Zo lang de visitatie nog niet door uw hoofd is opgestuurd kan u dit formulier nog wijzigen.</mat-card>
        </div>
      </div>
      <div class="u-spacer-small"></div>
    </ng-container>
    <div class="row">
      <div class="twelve columns">
        <button type="submit" [disabled]="!form.valid || typing" mat-flat-button color="primary">
          Versturen
        </button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #geen>
  <div class="row">
    <div class="twelve columns">
      <h1>Bedankt voor het invullen van de vragen</h1>
      <p>
        Tijdens de visitatie zal de visiteur de antwoorden met u bespreken.
      </p>
    </div>
  </div>
</ng-template>
