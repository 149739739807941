import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OpenVraag } from '../../models/openvraag';
import { AntwoordType } from '../../models/antwoordtype';

@Component({
  selector: 'open-vraag',
  templateUrl: './open-vraag.component.html',
  styleUrls:['./open-vraag.component.css'],
})
export class OpenVraagComponent {
  @Input() vraag: OpenVraag;
  @Input() form: FormGroup;

  public get isRequired(): boolean {
    return this.form.controls[this.vraag.key].hasError('required');
  }

  public get invalidPattern(): boolean {
    return this.form.controls[this.vraag.key].hasError('pattern');
  }

  public get invalidEmail(): boolean {
    return this.form.controls[this.vraag.key].hasError('email');
  }

  public get inputType(): string {
    switch (this.vraag.antwoordType) {
      case AntwoordType.Datum:
        return 'date';
      case AntwoordType.Email:
        return 'email';
      case AntwoordType.Nummer:
        return 'number';
      case AntwoordType.Telefoon:
        return 'tel';
      case AntwoordType.Tekst:
      default:
        return 'text';
    }
  }
}
