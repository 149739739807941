import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface LoaderState {
   loading: boolean;
   workBeingDone: number;
}

export function createInitialState(): LoaderState {
  return {
    loading: false,
    workBeingDone: 0
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'loader' })
export class LoaderStore extends Store<LoaderState> {

  constructor() {
    super(createInitialState());
  }

}

