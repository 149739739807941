import { Injectable } from '@angular/core';
import {  StoreConfig, Store } from '@datorama/akita';
import { Identity } from '../models/identity.model';
import { AuthStatus } from '../models/authstatus.model';

export function createInitialState(): Identity {
  return {
    rol: undefined,
    ziekenhuis: '',
    expires: null,
    id: '',
    authStatus: AuthStatus.Unauthenticated,
    jwt: '',
  } as Identity;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'identity' })
export class IdentityStore extends Store<Identity> {

  constructor() {
    super(createInitialState());
  }

}

