import { VisitatieStatus } from './visitatie-status';
import { Gebruiker } from './gebruiker';
import { MedewerkerVragen } from './medewerkervragen';
import { Onderwerp } from './onderwerp';

export class Visitatie {
  public id: string;
  public ziekenhuisNaam: string;
  public visitatieDatum: Date;
  public vorigeVisitatieDatum: Date;
  public visitatiecommissie: Gebruiker[] = [];
  public kwaliteitcommissie: string[] = [];
  public status: VisitatieStatus;
  public hoofdVragen: Onderwerp[];
  public medewerkerVragen: MedewerkerVragen[];
  public visiteurVragen: Onderwerp[] = [];

  constructor(obj?: Partial<Visitatie>) {
    Object.assign(this, obj);
  }
}
