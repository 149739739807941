import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'nhv-question-datum',
  templateUrl: './question-datum.component.html',
  styleUrls: []
})
export class QuestionDatumComponent implements OnInit {

  @Input() datum : Date;
  @Input() label : string;

  @Output() change = new EventEmitter<Date>();

  public controlvalue: string;

  constructor() { }

  ngOnInit(): void {
  }

  public setDatum(event: MatDatepickerInputEvent<Date>): void {
    this.datum = event.value;
    this.change.emit(this.datum);
  }
}
