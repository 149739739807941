import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OnderwerpState } from './onderwerp.state';
import { OnderwerpStore } from './onderwerp.store';
import { Onderwerp } from '../models/onderwerp';

@Injectable({ providedIn: 'root' })
export class OnderwerpQuery extends QueryEntity<OnderwerpState, Onderwerp> {

  constructor(protected store: OnderwerpStore) {
    super(store);
  }

  onderwerpen$ = this.selectAll();

}
